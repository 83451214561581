import React from 'react';
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import { getCurrentTimezone } from '~reactHelpers';
import { createCSVData, convertJsonToCsv, downloadCsv } from '~utils/helpers';
import { strings } from '~utils/strings';

dayjs.extend(timezone);

const ExportButton = ({ data, filename, text, labels }) => {
  const timeZone = getCurrentTimezone();

  const createFilename = (filename: string) => {
    return `${filename}_${
      timeZone ? dayjs().tz(timeZone).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')
    }`;
  };

  const exportCSV = async (data, filename: string) => {
    const converted = await convertJsonToCsv(data);
    return downloadCsv(converted, filename);
  };
  return (
    <Button
      className="admin-export-btn"
      variant="primary"
      onClick={async () => {
        let updatedData = data;
        if (labels) {
          updatedData = await createCSVData(data, labels);
        }
        exportCSV(updatedData, createFilename(filename));
      }}
      title={text}
    >
      {text.includes(strings.Download) ? strings.Download : text}
    </Button>
  );
};

export default ExportButton;
