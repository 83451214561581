import React, { useMemo } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import {
  useTable,
  useSortBy,
  useResizeColumns,
  useFlexLayout,
  usePagination,
  Column,
} from 'react-table';

import Table from '~reactComponents/Table/Table.react';
import Pagination from '~reactComponents/pagination/pagination.react';
import ShowingResults from '~reactComponents/ShowingResults/ShowingResults.react';

import { PAGE_SIZE_OPTIONS } from '../components.config.react';

import { strings } from '~utils/strings';

import './tableWithPagination.react.scss';

type TableWithPaginationProps = {
  columns: Column[];
  data: Record<string, any>[];
  filteredLength: number;
  tableCaption: string;
  className?: string;
};

const TableWithPagination = ({
  columns,
  data,
  filteredLength,
  tableCaption,
  className,
}: TableWithPaginationProps) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      state: { pageSize: 10 },
    },
    useFlexLayout,
    useSortBy,
    useResizeColumns,
    usePagination,
  );

  const {
    setPageSize,
    state: { pageSize, pageIndex },
  } = tableInstance;

  const totalPage = useMemo(
    () => Math.ceil(filteredLength / tableInstance.state.pageSize),
    [filteredLength, tableInstance.state.pageIndex, tableInstance.state.pageSize],
  );

  const handlePaginationChange = (page: number): void => {
    if (page < totalPage) {
      tableInstance.gotoPage(page);
    }
  };

  return (
    <div className="paginated-table">
      <div className="paginated-table__top-container">
        <div className="paginated-table__page-info">
          <ShowingResults size={pageSize} index={pageIndex} numOfRows={filteredLength} />
          <div className="paginated-table__results-per-page">
            <span>{strings.resultsPerPage}</span>
            <DropdownButton title={pageSize} size="sm">
              {PAGE_SIZE_OPTIONS?.map((pageSizeOption, idx) => (
                <Dropdown.Item key={idx} onClick={() => setPageSize(pageSizeOption)}>
                  {pageSizeOption}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        </div>
      </div>
      <div className={`paginated-table__table-container ${className}`}>
        <Table
          tableInstance={tableInstance}
          caption={tableCaption}
          className="paginated-table__customized-table"
          pagination={
            <Pagination
              currentPage={tableInstance.state.pageIndex}
              pageChange={handlePaginationChange}
              totalCount={totalPage}
            />
          }
        />
      </div>
    </div>
  );
};

export default TableWithPagination;
