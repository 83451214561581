import React, { useMemo } from 'react';
import { useTable, useSortBy, useResizeColumns, useFlexLayout, usePagination } from 'react-table';

import ExportButton from '../exportButton/exportButton.react';
import Table from '~reactComponents/Table/Table.react';

import { FUNNEL_TABLE_COLUMNS } from '../components.config.react';

import { FunnelDataProp } from '~views/admin/Admin.types.react';

import { strings } from '~utils/strings';
import { Title } from '~utils/texts.react';

import './funnelData.react.scss';

const FunnelData = ({ funnelData }: { funnelData: FunnelDataProp[] }) => {
  const columns = useMemo(() => FUNNEL_TABLE_COLUMNS, [funnelData]);

  const data = useMemo(() => funnelData, [funnelData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
    useSortBy,
    useResizeColumns,
    usePagination,
  );

  return (
    <div className="funnel-data">
      <h2
        className={Title({
          size: strings.textStyling.xs,
          weight: strings.textStyling.medium,
          topMargin: strings.textStyling.lg,
        })}
      >
        {strings.admin.funnelData}
      </h2>
      <ExportButton
        data={funnelData}
        filename="funnel_data"
        text={strings.admin.exportFunnelData}
      />
      <div className="funnel-data__table-container">
        <Table
          tableInstance={tableInstance}
          caption={strings.admin.funnelDataCaption}
          className="funnel-data__customized-table"
        />
      </div>
    </div>
  );
};

export default FunnelData;
