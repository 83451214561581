import React from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Row } from 'react-table';
import dayjs from 'dayjs';

import {
  removeAllowedDomain,
  enableInstallationUser,
  disableInstallationUser,
  enableInstallation,
  disableInstallation,
  updateInstallationTier,
} from '../Admin.helpers.react';

import {
  AllowedDomainsDataProp,
  SelfHostedUsersDataProp,
  SelfHostedInstallationDataProp,
  UserDataProp,
} from '../Admin.types.react';

import { CLUSTERS } from '~reactComponents/NavigationReact/Navigation.config.react';

import { strings } from '~utils/strings';
import { IRouter } from '~utils/global.types.react';

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 250];

const orgTierRewrite = [
  strings.admin.freeTier,
  strings.admin.enterpriseTier,
  strings.admin.businessTier,
  strings.admin.disabledTier,
];

const options = [strings.admin.endTrial, strings.admin.trial14, strings.admin.trial30, strings.admin.trial60];

const timeOptions = [0, 14, 30, 60];

const userRoles = [
  { value: 'Regular User', display: strings.admin.regularUser },
  { value: 'SuperAdmin', display: strings.admin.superAdmin },
  { value: 'SuperDuperAdmin', display: strings.admin.superDuperAdmin },
];

export const FUNNEL_TABLE_COLUMNS = [
  {
    Header: strings.admin.Funnel,
    accessor: 'Funnel',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => {
      return <span title={value}>{value}</span>;
    },
    minWidth: 550,
  },
  {
    Header: strings.admin.Count,
    accessor: 'Count',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      return <span title={`${value}`}>{value}</span>;
    },
    minWidth: 110,
  },
];

export const DOMAINS_TABLE_COLUMNS = (
  setAllowedDomains: React.Dispatch<React.SetStateAction<AllowedDomainsDataProp[]>>,
) => {
  return [
    {
      Header: strings.admin.ID,
      accessor: 'id',
      className: 'no-overflow',
      Cell: ({ value }: { value: number }) => {
        return <span title={`${value}`}>{value}</span>;
      },
      minWidth: 110,
    },
    {
      Header: strings.admin.Domain,
      accessor: strings.noTranslate.domain,
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => {
        return <span title={value}>{value}</span>;
      },
      minWidth: 550,
    },
    {
      Header: strings.admin.Actions,
      id: 'actions',
      accessor: 'id',
      className: 'no-overflow',
      Cell: ({ value }: { value: number }) => {
        return (
          <Button variant="primary" onClick={() => removeAllowedDomain(value, setAllowedDomains)}>
            {strings.admin.removeDomain}
          </Button>
        );
      },
      minWidth: 250,
    },
  ];
};

export const SELF_HOSTED_USERS_TABLE_COLUMNS = (
  setInstallationUsers: React.Dispatch<React.SetStateAction<SelfHostedUsersDataProp[]>>,
) => {
  return [
    {
      Header: strings.admin.Email,
      accessor: 'Email',
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => {
        return <span title={value}>{value}</span>;
      },
      minWidth: 325,
    },
    {
      Header: strings.admin.installationUUID,
      accessor: 'InstallationUUID',
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => {
        return <span title={value}>{value}</span>;
      },
      minWidth: 400,
    },
    {
      Header: strings.admin.Disabled,
      accessor: 'Disabled',
      className: 'no-overflow',
      Cell: ({ value, row }: { value: boolean; row: Row }) => {
        const { Email, InstallationUUID } = row.original;
        const buttonValue = value ? strings.admin.reenableUser : strings.admin.disableUser;

        return (
          <Button
            variant="primary"
            onClick={() => {
              if (value) {
                enableInstallationUser(InstallationUUID, Email, setInstallationUsers);
              } else {
                disableInstallationUser(InstallationUUID, Email, setInstallationUsers);
              }
            }}
          >
            {buttonValue}
          </Button>
        );
      },
      minWidth: 250,
    },
  ];
};

export const SELF_HOSTED_INSTALLATIONS_TABLE_COLUMNS = (
  setInstallations: React.Dispatch<React.SetStateAction<SelfHostedInstallationDataProp[]>>,
) => {
  return [
    {
      Header: strings.admin.installationUUID,
      accessor: 'UUID',
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => {
        return <span title={value}>{value}</span>;
      },
      minWidth: 300,
    },
    {
      Header: strings.admin.Name,
      accessor: 'Name',
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => {
        return <span title={value}>{value}</span>;
      },
      minWidth: 200,
    },
    {
      Header: strings.admin.Tier,
      accessor: 'Tier',
      Cell: ({ value, row }: { value: number; row: Row }) => {
        const { UUID } = row.original;

        return (
          <DropdownButton title={orgTierRewrite[value]} variant="secondary">
            {orgTierRewrite.map((tier, index) => (
              <Dropdown.Item onClick={() => updateInstallationTier(UUID, null, index, setInstallations)}>
                {tier}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        );
      },
      minWidth: 180,
    },
    {
      Header: strings.admin.trialExpiration,
      accessor: 'TrialExpiresAt',
      Cell: ({ value, row }: { value: string; row: Row }) => {
        const { UUID } = row.original;
        const convertedDate = value ? dayjs(value) : '';
        let currentTrial = strings.admin.notInTrial;
        if (value) {
          currentTrial = dayjs().isAfter(convertedDate)
            ? strings.admin.trialExpired
            : `${strings.admin.Expires} ${convertedDate.format('MM/DD/YY LT')}`;
        }

        return (
          <DropdownButton title={currentTrial} variant="secondary">
            {options.map((trial, index) => (
              <Dropdown.Item
                onClick={() => updateInstallationTier(UUID, timeOptions[index], index === 0 ? 0 : 1, setInstallations)}
              >
                {trial}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        );
      },
      minWidth: 280,
    },
    {
      Header: strings.admin.Disabled,
      accessor: 'Disabled',
      Cell: ({ value, row }: { value: boolean; row: Row }) => {
        const { UUID } = row.original;
        const buttonValue = value ? strings.admin.reenableInstallation : strings.admin.disableInstallation;

        return (
          <Button
            variant="primary"
            onClick={() => {
              if (value) {
                enableInstallation(UUID, setInstallations);
              } else {
                disableInstallation(UUID, setInstallations);
              }
            }}
          >
            {buttonValue}
          </Button>
        );
      },
      minWidth: 290,
    },
  ];
};

export const USERS_TABLE_COLUMNS = (
  updateTier: (org: string, tier: number) => void,
  updateTrial: (org: string, period?: number) => void,
  setUserType: (email: string, role: string) => void,
  toggleCanCreateOrg: (email: string) => void,
  removeUserFromOrganization: (email: string, org: string) => void,
  deleteUser: (email: string) => void,
  router: () => IRouter,
) => {
  return [
    {
      Header: strings.admin.Email,
      accessor: 'Email',
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => {
        return <span title={value}>{value}</span>;
      },
      minWidth: 325,
    },
    {
      Header: strings.general.Organization,
      accessor: 'Organization',
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => {
        return <a href={router().resolve({ name: CLUSTERS, params: { org: value } }).href}>{value}</a>;
      },
      minWidth: 200,
    },
    {
      Header: strings.admin.clusterCount,
      accessor: 'ClusterCount',
      className: 'no-overflow',
      Cell: ({ value }: { value: number }) => {
        return <span title={`${value}`}>{value}</span>;
      },
      minWidth: 40,
    },
    {
      Header: strings.admin.agentsInstalled,
      accessor: 'AgentCount',
      className: 'no-overflow',
      Cell: ({ value }: { value: number }) => {
        return <span title={`${value}`}>{value}</span>;
      },
      minWidth: 40,
    },
    {
      Header: strings.admin.latestReport,
      accessor: 'LatestReportTimestamp',
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => {
        return <span title={value}>{value !== 'N/A' ? dayjs(value).format('MM/DD/YY LT') : value}</span>;
      },
      minWidth: 250,
    },
    {
      Header: strings.admin.orgTier,
      accessor: 'OrgTierText',
      Cell: ({ value, row }: { value: string; row: Row }) => {
        const { Organization } = row.original as UserDataProp;

        if (value === 'N/A' || !value) return <></>;

        return (
          <DropdownButton title={value} variant="secondary">
            {orgTierRewrite.map((tier, index) => (
              <Dropdown.Item onClick={() => updateTier(Organization, index)}>{tier}</Dropdown.Item>
            ))}
          </DropdownButton>
        );
      },
      minWidth: 180,
    },
    {
      Header: strings.admin.trialStatus,
      accessor: 'TrialExpiresAt',
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => {
        return <span title={value}>{value}</span>;
      },
      minWidth: 150,
    },
    {
      Header: strings.admin.updateTrial,
      accessor: 'UpdateTrial',
      Cell: ({ value, row }: { value: string; row: Row }) => {
        const { Organization } = row.original as UserDataProp;

        if (value === 'N/A' || !value) return <></>;

        return (
          <DropdownButton title={value} variant="secondary">
            {options.map((trial, index) => (
              <Dropdown.Item onClick={() => updateTrial(Organization, timeOptions[index])}>{trial}</Dropdown.Item>
            ))}
          </DropdownButton>
        );
      },
      minWidth: 180,
    },
    {
      Header: strings.admin.orgCreateDate,
      accessor: 'OrgCreatedOn',
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => {
        return <span title={value}>{value !== 'N/A' ? dayjs(value).format(strings.dateFormats.LLL) : value}</span>;
      },
      minWidth: 300,
    },
    {
      Header: strings.admin.orgMembership,
      accessor: 'OrgMembership',
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => {
        return <span title={value}>{value}</span>;
      },
      minWidth: 100,
    },
    {
      Header: strings.admin.userCreateDate,
      accessor: 'CreatedOn',
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => {
        return <span title={value}>{value !== 'N/A' ? dayjs(value).format(strings.dateFormats.LLL) : value}</span>;
      },
      minWidth: 300,
    },
    {
      Header: strings.admin.userLastLogin,
      accessor: 'LastLoginOn',
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => {
        return <span title={value}>{value !== 'N/A' ? dayjs(value).format(strings.dateFormats.LLL) : value}</span>;
      },
      minWidth: 300,
    },
    {
      Header: strings.admin.userLoginCount,
      accessor: 'AllTimeLoginCount',
      className: 'no-overflow',
      Cell: ({ value }: { value: number }) => {
        return <span title={`${value}`}>{value}</span>;
      },
      minWidth: 50,
    },
    {
      Header: `${strings.admin.userLoginCount} (7d)`,
      accessor: 'WeeklyLoginCount',
      className: 'no-overflow',
      Cell: ({ value }: { value: number }) => {
        return <span title={`${value}`}>{value}</span>;
      },
      minWidth: 80,
    },
    {
      Header: `${strings.admin.userLoginCount} (30d)`,
      accessor: 'MonthlyLoginCount',
      className: 'no-overflow',
      Cell: ({ value }: { value: number }) => {
        return <span title={`${value}`}>{value}</span>;
      },
      minWidth: 80,
    },
    {
      Header: strings.admin.updateUserType,
      accessor: 'UserTypeText',
      Cell: ({ value, row }: { value: string; row: Row }) => {
        const { Email } = row.original;

        const matched = userRoles.find((role) => role.value === value);

        return (
          <DropdownButton title={matched?.display || ''} variant="secondary">
            {userRoles
              .filter((userRole) => userRole.value !== value)
              .map((role) => (
                <Dropdown.Item onClick={() => setUserType(Email, role.value)}>{role.display}</Dropdown.Item>
              ))}
          </DropdownButton>
        );
      },
      minWidth: 190,
    },
    {
      Header: strings.admin.canCreateOrg,
      accessor: 'CanCreateOrg',
      Cell: ({ row }: { row: Row }) => {
        const { Email, CanCreateOrg } = row.original as UserDataProp;
        return (
          <Button
            type="button"
            variant={CanCreateOrg ? 'success' : 'warning'}
            onClick={() => toggleCanCreateOrg(Email)}
          >
            {CanCreateOrg ? strings.general.Yes : strings.general.No}
          </Button>
        );
      },
      minWidth: 190,
    },
    {
      Header: strings.admin.orgCreatedBy,
      accessor: 'OrgCreatedBy',
      className: 'no-overflow',
      Cell: ({ value }: { value: string }) => {
        return <span title={value}>{value}</span>;
      },
      minWidth: 200,
    },
    {
      Header: strings.admin.Actions,
      accessor: 'Actions',
      Cell: ({ value, row }: { value: string; row: Row }) => {
        const { Email, Organization } = row.original;

        return (
          <>
            {(!value || !value.length) && (
              <Button
                variant="primary"
                onClick={() => removeUserFromOrganization(Email, Organization)}
                title={strings.admin.removeUserFromOrg}
                style={{ marginRight: '0.5rem' }}
              >
                {strings.admin.removeUserFromOrg}
              </Button>
            )}
            <Button variant="danger" onClick={() => deleteUser(Email)} title={strings.admin.deleteUser}>
              {strings.admin.deleteUser}
            </Button>
          </>
        );
      },
      minWidth: 475,
    },
  ];
};
