import React, { useState, useMemo, useEffect } from 'react';
import Fuse from 'fuse.js';

import TableWithPagination from '../tableWithPagination/tableWithPagination.react';

import { USERS_TABLE_COLUMNS } from '../components.config.react';

import { IRouter } from '~utils/global.types.react';
import { UserDataProp } from '~views/admin/Admin.types.react';

import { strings } from '~utils/strings';
import { Title } from '~utils/texts.react';

type UsersProp = {
  usersData: UserDataProp[];
  updateTier: (org: string, tier: number) => void;
  updateTrial: (org: string, period?: number) => void;
  setUserType: (email: string, role: string) => void;
  toggleCanCreateOrg: (email: string) => void;
  removeUserFromOrganization: (email: string, org: string) => void;
  deleteUser: (email: string) => void;
  search: string;
  router: () => IRouter;
};

const Users = ({
  usersData,
  updateTier,
  updateTrial,
  setUserType,
  toggleCanCreateOrg,
  removeUserFromOrganization,
  deleteUser,
  search,
  router,
}: UsersProp) => {
  const [filteredUsers, setFilteredUsers] = useState<UserDataProp[]>([]);

  useEffect(() => {
    setFilteredUsers(usersData);
  }, [usersData]);

  const searchInstance = useMemo(() => {
    return new Fuse(usersData as UserDataProp[], {
      findAllMatches: true,
      threshold: 0.2,
      ignoreLocation: true,
      keys: ['Organization', 'Email'],
    });
  }, [usersData]);

  useEffect(() => {
    if (!search.length) {
      setFilteredUsers(usersData);
    } else {
      const results = searchInstance.search(search).map((res) => res.item);
      setFilteredUsers(results);
    }
  }, [search, usersData]);

  const columns = useMemo(
    () =>
      USERS_TABLE_COLUMNS(
        updateTier,
        updateTrial,
        setUserType,
        toggleCanCreateOrg,
        removeUserFromOrganization,
        deleteUser,
        router,
      ),
    [filteredUsers],
  );

  const data = useMemo(() => filteredUsers, [filteredUsers, usersData]);

  return (
    <div>
      <h2
        className={Title({
          size: strings.textStyling.xs,
          weight: strings.textStyling.medium,
          topMargin: strings.textStyling.md,
        })}
      >
        {strings.admin.Users}
      </h2>
      <TableWithPagination
        columns={columns}
        data={data}
        filteredLength={filteredUsers.length}
        tableCaption={strings.admin.selfHostedInstallationsCaption}
        className="admin-table"
      />
    </div>
  );
};

export default Users;
