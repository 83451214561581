import React, { useState, useMemo, useEffect } from 'react';
import Fuse from 'fuse.js';

import TableWithPagination from '../tableWithPagination/tableWithPagination.react';

import { SELF_HOSTED_INSTALLATIONS_TABLE_COLUMNS } from '../components.config.react';

import { ISelfHostedInstallation } from '~views/admin/Admin.types.react';

import { strings } from '~utils/strings';
import { Title } from '~utils/texts.react';

type SelfHostedInstallationsProp = {
  installationsData: ISelfHostedInstallation[];
  setInstallations: any;
  search: string;
};

const SelfHostedInstallations = ({
  installationsData,
  setInstallations,
  search,
}: SelfHostedInstallationsProp) => {
  const [filteredInstallations, setFilteredInstallations] = useState<ISelfHostedInstallation[]>([]);

  useEffect(() => {
    setFilteredInstallations(installationsData);
  }, [installationsData.length]);

  const searchInstance = useMemo(() => {
    return new Fuse(installationsData as ISelfHostedInstallation[], {
      findAllMatches: true,
      threshold: 0.2,
      ignoreLocation: true,
      keys: ['Name', 'UUID'],
    });
  }, [installationsData.length]);

  useEffect(() => {
    if (!search.length) {
      setFilteredInstallations(installationsData);
    } else {
      const results = searchInstance.search(search).map((res) => res.item);
      setFilteredInstallations(results);
    }
  }, [search, installationsData]);

  const columns = useMemo(
    () => SELF_HOSTED_INSTALLATIONS_TABLE_COLUMNS(setInstallations),
    [filteredInstallations],
  );

  const data = useMemo(() => filteredInstallations, [filteredInstallations, installationsData]);

  return (
    <div>
      <h2
        className={Title({
          size: strings.textStyling.xs,
          weight: strings.textStyling.medium,
          topMargin: strings.textStyling.lg,
        })}
      >
        {strings.admin.selfHostedInstallations}
      </h2>
      <TableWithPagination
        columns={columns}
        data={data}
        filteredLength={filteredInstallations.length}
        tableCaption={strings.admin.selfHostedInstallationsCaption}
      />
    </div>
  );
};

export default SelfHostedInstallations;
