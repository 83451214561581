import React, { useState, useMemo, useEffect } from 'react';
import Fuse from 'fuse.js';

import TableWithPagination from '../tableWithPagination/tableWithPagination.react';

import { SELF_HOSTED_USERS_TABLE_COLUMNS } from '../components.config.react';

import { SelfHostedUsersDataProp } from '~views/admin/Admin.types.react';

import { strings } from '~utils/strings';
import { Title } from '~utils/texts.react';

type SelfHostedUsersProp = {
  usersData: SelfHostedUsersDataProp[];
  setInstallationUsers: React.Dispatch<React.SetStateAction<SelfHostedUsersDataProp[]>>;
  search: string;
};

const SelfHostedUsers = ({ usersData, setInstallationUsers, search }: SelfHostedUsersProp) => {
  const [filteredUsers, setFilteredUsers] = useState<SelfHostedUsersDataProp[]>([]);

  useEffect(() => {
    setFilteredUsers(usersData);
  }, [usersData.length]);

  const searchInstance = useMemo(() => {
    return new Fuse(usersData as SelfHostedUsersDataProp[], {
      findAllMatches: true,
      threshold: 0.2,
      ignoreLocation: true,
      keys: ['Email', 'InstallationUUID'],
    });
  }, [usersData.length]);

  useEffect(() => {
    if (!search.length) {
      setFilteredUsers(usersData);
    } else {
      const results = searchInstance.search(search).map((res) => res.item);
      setFilteredUsers(results);
    }
  }, [search, usersData]);

  const columns = useMemo(
    () => SELF_HOSTED_USERS_TABLE_COLUMNS(setInstallationUsers),
    [filteredUsers],
  );

  const data = useMemo(() => filteredUsers, [filteredUsers, usersData]);

  return (
    <div>
      <h2
        className={Title({
          size: strings.textStyling.xs,
          weight: strings.textStyling.medium,
          topMargin: strings.textStyling.lg,
        })}
      >
        {strings.admin.selfHostedUsers}
      </h2>
      <TableWithPagination
        columns={columns}
        data={data}
        filteredLength={filteredUsers.length}
        tableCaption={strings.admin.selfHostedUsersCaption}
      />
    </div>
  );
};

export default SelfHostedUsers;
