import React from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import toast from 'react-hot-toast';

import { orgTierRewrite } from './Admin.config.react';
import {
  LatestReportProp,
  LastLoginTimeProp,
  AgentCountsProp,
  LoginCountProp,
  UserProp,
  UserMembership,
  OrgProp,
  AllowedDomainsDataProp,
  SelfHostedInstallationDataProp,
  SelfHostedUsersDataProp,
  UserDataProp,
} from './Admin.types.react';

import { getCurrentTimezone, hasKey } from '~reactHelpers';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { strings } from '~utils/strings';

dayjs.extend(timezone);

export const formatTime = (time: string) => {
  const timeZone = getCurrentTimezone();
  if (dayjs(time).isValid()) {
    return timeZone
      ? dayjs(time).tz(timeZone).format(strings.dateFormats.LLL)
      : dayjs(time).format(strings.dateFormats.LLL);
  }
  return time;
};

export const refreshLatestReportEvents = async (
  setLatestReportEvents: React.Dispatch<React.SetStateAction<LatestReportProp[]>>,
) => {
  let response;
  try {
    response = await sendRequest('GET', '/v0/admin/latestReports', {}, null);
  } catch (e) {
    logger.logError('error_retrieving_latest_report_events', e);
  }
  setLatestReportEvents(response);
};

export const refreshLastLoginTimes = async (
  setLastLoginTimes: React.Dispatch<React.SetStateAction<Record<string, LastLoginTimeProp>>>,
) => {
  let response;
  try {
    response = await sendRequest('GET', '/v0/admin/lastLoginTimes', {}, null);
  } catch (e) {
    logger.logError('error_retrieving_last_login_times', e);
  }
  setLastLoginTimes(response);
};

export const refreshAgentCounts = async (
  setAgentCounts: React.Dispatch<React.SetStateAction<Record<string, AgentCountsProp>>>,
) => {
  let response;
  try {
    response = await sendRequest('GET', '/v0/admin/agents', {}, null);
  } catch (e) {
    logger.logError('error_retrieving_agent_counts', e);
  }
  setAgentCounts(response);
};

export const refreshLoginCounts = async (
  setLoginCounts: React.Dispatch<React.SetStateAction<LoginCountProp | null>>,
) => {
  let response;
  try {
    response = await sendRequest('GET', '/v0/admin/loginCounts', {}, null);
  } catch (e) {
    logger.logError('error_retrieving_login_counts', e);
  }
  setLoginCounts(response);
};

export const refreshMemberships = async (
  setUsers: React.Dispatch<React.SetStateAction<UserProp[]>>,
  setUserMemberships: React.Dispatch<React.SetStateAction<Record<string, UserMembership[]>>>,
) => {
  let memberships;
  try {
    memberships = await sendRequest('GET', '/v0/admin/memberships', {}, null);
  } catch (e) {
    logger.logError('error_retrieving_admin_memberships', e);
  }
  setUsers(memberships.users);
  setUserMemberships(memberships.userMemberships);
};

export const refreshOrganizations = async (
  setClusterCounts: React.Dispatch<React.SetStateAction<Record<string, number>>>,
  setOrgs: React.Dispatch<React.SetStateAction<OrgProp[]>>,
) => {
  let organizations;
  try {
    organizations = await sendRequest('GET', '/v0/admin/organizations', {}, null);
  } catch (e) {
    logger.logError('error_retrieving_admin_orgs', e);
  }
  setClusterCounts(organizations.clusterCounts);
  setOrgs(organizations.orgs);
};

export const refreshAllowedDomains = async (
  setAllowedDomains: React.Dispatch<React.SetStateAction<AllowedDomainsDataProp[]>>,
) => {
  let response;
  try {
    response = await sendRequest('GET', '/v0/admin/allowed-domains', {}, null);
  } catch (e) {
    logger.logError('error_retrieving_allowed_domains', e);
  }
  setAllowedDomains(response.allowedDomains);
};

export const refreshInstallations = async (
  setInstallations: React.Dispatch<React.SetStateAction<SelfHostedInstallationDataProp[]>>,
) => {
  let installations;
  try {
    installations = await sendRequest('GET', '/v0/admin/self-hosted/installations', {}, null);
  } catch (e) {
    logger.logError('error_retrieving_self_hosted_installations', e);
  }
  setInstallations(installations);
};

export const refreshInstallationUsers = async (
  setInstallationUsers: React.Dispatch<React.SetStateAction<SelfHostedUsersDataProp[]>>,
) => {
  let installationUsers;
  try {
    installationUsers = await sendRequest('GET', '/v0/admin/self-hosted/users', {}, null);
  } catch (e) {
    logger.logError('error_retrieving_self_hosted_users', e);
  }
  setInstallationUsers(installationUsers);
};

export const getUserType = (user: UserProp) => {
  let userType;

  if (user.IsSuperDuperAdmin) {
    userType = strings.admin.superDuperAdmin;
  } else if (user.IsSuperAdmin) {
    userType = strings.admin.superAdmin;
  } else {
    userType = strings.admin.regularUser;
  }

  user.UserTypeText = userType;
  return user;
};

const getLoginCountForUser = (email: string, key: string, loginCounts: LoginCountProp) => {
  if (hasKey(loginCounts, key)) {
    if (!loginCounts[key][email]) {
      return 0;
    }
    return loginCounts[key][email].LoginCount;
  }
};

const getLastLoginTimeForUser = (
  email: string,
  lastLoginTimes: Record<string, LastLoginTimeProp>,
) => {
  if (!lastLoginTimes?.[email]) {
    return null;
  }
  return lastLoginTimes[email].LoginTime;
};

export const getLoginInfoForUser = (
  userOrgMap: UserDataProp,
  email: string,
  loginCounts: LoginCountProp,
  lastLoginTimes: Record<string, LastLoginTimeProp>,
) => {
  userOrgMap.AllTimeLoginCount = getLoginCountForUser(email, 'AllLoginCountsByUser', loginCounts);
  userOrgMap.WeeklyLoginCount = getLoginCountForUser(email, 'WeeklyLoginCountsByUser', loginCounts);
  userOrgMap.MonthlyLoginCount = getLoginCountForUser(
    email,
    'MonthlyLoginCountsByUser',
    loginCounts,
  );
  userOrgMap.LastLoginOn = getLastLoginTimeForUser(email, lastLoginTimes);

  return userOrgMap;
};

export const getOrgByName = (orgName: string, orgs: OrgProp[]) => {
  return orgs.find((org) => org.Name === orgName);
};

export const trialRewrite = (trialExpiresAt: string | Date | null) => {
  if (trialExpiresAt == null) {
    return strings.admin.notInTrial;
  }
  if (Date.parse(trialExpiresAt) < Date.now()) {
    return strings.admin.trialExpired;
  }
  return strings.admin.inTrial;
};

export const getOrgFieldsForUserTableRows = (
  oldUserOrgMap: UserDataProp,
  org: OrgProp | null,
  NA = false,
) => {
  const userOrgMap = oldUserOrgMap;

  if (org) {
    userOrgMap.OrgCreatedOn = NA ? strings.general.na : org?.CreatedOn;
    userOrgMap.OrgCreatedBy = NA ? strings.general.na : org?.CreatedBy || strings.policies.Unknown;
    userOrgMap.OrgTier = NA ? strings.general.na : org?.Tier;
    userOrgMap.OrgTierText = NA ? strings.general.na : orgTierRewrite?.[org?.Tier];
    userOrgMap.Organization = NA ? strings.general.na : org.Name;
  }
  if (NA) {
    userOrgMap.TrialExpiresAt = strings.general.na;
  } else {
    userOrgMap.TrialExpiresAt = org?.TrialExpiresAt ? org.TrialExpiresAt : strings.admin.notInTrial;
  }

  userOrgMap.UpdateTrial = NA ? strings.general.na : trialRewrite(org?.TrialExpiresAt || '');

  return userOrgMap;
};

export const getAgentFieldsForUserTableRows = (
  oldUserOrgMap: UserDataProp,
  NA = false,
  agentCounts: Record<string, AgentCountsProp>,
) => {
  const userOrgMap = oldUserOrgMap;
  userOrgMap.AgentCount = NA ? strings.general.na : agentCounts?.[userOrgMap.Organization]?.Count;

  let timestamp = null;
  if (NA || agentCounts?.[userOrgMap.Organization]?.LatestTimeStamp == null) {
    timestamp = strings.general.na;
  } else {
    timestamp = agentCounts?.[userOrgMap.Organization]?.LatestTimeStamp;
  }
  userOrgMap.LatestReportTimestamp = timestamp || null;

  return userOrgMap;
};

export const addAllowedDomain = async (
  domain: string,
  setAllowedDomains: React.Dispatch<React.SetStateAction<AllowedDomainsDataProp[]>>,
) => {
  const url = '/v0/admin/allowed-domains';
  const body = {
    domain,
  };
  let allowedDomain: AllowedDomainsDataProp;

  try {
    allowedDomain = await sendRequest(
      'POST',
      url,
      {
        showSuccessAlert: true,
        data: body,
      },
      null,
    );

    toast.success(`Added domain ${domain}`);
    setAllowedDomains((prevState) => [...prevState, allowedDomain]);
  } catch (e) {
    toast.error(`Error adding domain ${domain}: ${e.message}`);
    logger.logError('error_adding_allowed_domain', e);
  }
};

export const removeAllowedDomain = async (
  allowedDomainID: number,
  setAllowedDomains: React.Dispatch<React.SetStateAction<AllowedDomainsDataProp[]>>,
) => {
  try {
    await sendRequest('DELETE', `/v0/admin/allowed-domains/${allowedDomainID}`, {}, null);

    toast.success('Domain deleted');
    await refreshAllowedDomains(setAllowedDomains);
  } catch (e) {
    toast.error('Error deleting domain');
    logger.logError('error_deleting_allowed_domain', e);
  }
};

export const enableInstallationUser = async (
  uuid: number,
  email: string,
  setInstallationUsers: React.Dispatch<React.SetStateAction<SelfHostedUsersDataProp[]>>,
) => {
  try {
    await sendRequest(
      'POST',
      `/v0/admin/self-hosted/installations/${uuid}/users/${email}/enable`,
      {},
      null,
    );

    toast.success(`Enabled self-hosted user ${email}`);
    await refreshInstallationUsers(setInstallationUsers);
  } catch (e) {
    toast.error(`Error enabling self-hosted user ${email}`);
    logger.logError('error_adding_self_hosted_user', e);
  }
};

export const disableInstallationUser = async (
  uuid: number,
  email: string,
  setInstallationUsers: React.Dispatch<React.SetStateAction<SelfHostedUsersDataProp[]>>,
) => {
  try {
    await sendRequest(
      'POST',
      `/v0/admin/self-hosted/installations/${uuid}/users/${email}/disable`,
      {},
      null,
    );
    toast.success(`Disabled self-hosted user ${email}`);
    await refreshInstallationUsers(setInstallationUsers);
  } catch (e) {
    toast.error(`Error disabling self-hosted user ${email}`);
    logger.logError('error_disabling_self_hosted_user', e);
  }
};

export const updateInstallationTier = async (
  uuid: number,
  duration: number | null,
  tier: number,
  setInstallations: React.Dispatch<React.SetStateAction<SelfHostedInstallationDataProp[]>>,
) => {
  let trialExpires;
  if (duration) {
    const tempDate = new Date();
    tempDate.setDate(tempDate.getDate() + duration);
    trialExpires = tempDate.toISOString();
  }
  const body = {
    TrialExpiresAt: trialExpires,
    Tier: tier,
  };

  try {
    await sendRequest(
      'POST',
      `/v0/admin/self-hosted/installations/${uuid}/setTier`,
      {
        data: body,
      },
      null,
    );
    toast.success('Updated self-hosted installation tier!');
    await refreshInstallations(setInstallations);
  } catch (e) {
    toast.error('Error updating self-hosted installation tier');
    logger.logError('error_updating_self_hosted_tier', e);
  }
};

export const enableInstallation = async (
  uuid: number,
  setInstallations: React.Dispatch<React.SetStateAction<SelfHostedInstallationDataProp[]>>,
) => {
  try {
    await sendRequest('POST', `/v0/admin/self-hosted/installations/${uuid}/enable`, {}, null);

    toast.success('Self-hosted installation enabled!');
    await refreshInstallations(setInstallations);
  } catch (e) {
    toast.error('Error enabling self-hosted installation');
    logger.logError('error_enabling_self_hosted_installation', e);
  }
};

export const disableInstallation = async (
  uuid: number,
  setInstallations: React.Dispatch<React.SetStateAction<SelfHostedInstallationDataProp[]>>,
) => {
  try {
    await sendRequest('POST', `/v0/admin/self-hosted/installations/${uuid}/disable`, {}, null);

    toast.success('Self-hosted installation disabled!');
    await refreshInstallations(setInstallations);
  } catch (e) {
    toast.error('Error disabling self-hosted installation');
    logger.logError('error_disabling_self_hosted_installation', e);
  }
};

export const generateInstallationCode = async (
  setInstallationCode: React.Dispatch<React.SetStateAction<string>>,
) => {
  const url = '/v0/admin/generateInstallationCode';
  let installation;
  try {
    installation = await sendRequest('POST', url, { showSuccessAlert: false }, null);
  } catch (e) {
    logger.logError('error_retrieving_self_hosted_code', e);
  }
  setInstallationCode(installation.Code);
};
