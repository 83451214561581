import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Button, Form } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';

import { LayoutReact } from '@fairwindsops/ui-components';

import ConfirmationDialog from '~reactComponents/ConfirmationDialog/ConfirmationDialog.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import ExportButton from './components/exportButton/exportButton.react';
import FunnelData from './components/funnelData/funnelData.react';
import AllowedDomains from './components/allowedDomains/allowedDomains.react';
import SelfHostedUsers from './components/selfHostedUsers/selfHostedUsers.react';
import SelfHostedInstallations from './components/selfHostedInstallations/selfHostedInstallations.react';
import Users from './components/users/users.react';

import { ENTERPRISE_TIER_INT, FREE_TIER_INT, orgTierRewrite, userOrgMapCsvLabels } from './Admin.config.react';

import {
  formatTime,
  refreshLatestReportEvents,
  refreshLastLoginTimes,
  refreshAgentCounts,
  refreshLoginCounts,
  refreshMemberships,
  refreshOrganizations,
  refreshAllowedDomains,
  refreshInstallations,
  refreshInstallationUsers,
  getOrgByName,
  trialRewrite,
  getAgentFieldsForUserTableRows,
  getLoginInfoForUser,
  getOrgFieldsForUserTableRows,
  getUserType,
  generateInstallationCode,
} from './Admin.helpers.react';

import {
  LatestReportProp,
  LastLoginTimeProp,
  AgentCountsProp,
  LoginCountProp,
  UserMembership,
  UserProp,
  OrgProp,
  AllowedDomainsDataProp,
  SelfHostedInstallationDataProp,
  SelfHostedUsersDataProp,
  UserDataProp,
  ISelfHostedInstallation,
} from './Admin.types.react';
import { IRouter } from '~utils/global.types.react';

import { AdminDomain } from '~utils/constants';
import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import logger from '~utils/logger';
import { Title } from '~utils/texts.react';

import './style.scss';

dayjs.extend(timezone);

const Admin = ({ router }: { router: () => IRouter }) => {
  const [userSearch, setUserSearch] = useState<string>('');
  const [users, setUsers] = useState<UserProp[]>([]);
  const [userMemberships, setUserMemberships] = useState<Record<string, UserMembership[]>>({});
  const [clusterCounts, setClusterCounts] = useState<Record<string, number>>({});
  const [agentCounts, setAgentCounts] = useState<Record<string, AgentCountsProp>>({});
  const [loginCounts, setLoginCounts] = useState<LoginCountProp | null>(null);
  const [loading, setLoading] = useState<boolean>();
  const [lastLoginTimes, setLastLoginTimes] = useState<Record<string, LastLoginTimeProp>>({});
  const [latestReportEvents, setLatestReportEvents] = useState<LatestReportProp[]>([]);
  const [allowedDomains, setAllowedDomains] = useState<AllowedDomainsDataProp[]>([]);
  const [installations, setInstallations] = useState<SelfHostedInstallationDataProp[]>([]);
  const [installationUsers, setInstallationUsers] = useState<SelfHostedUsersDataProp[]>([]);
  const [orgs, setOrgs] = useState<OrgProp[]>([]);
  const [installationCode, setInstallationCode] = useState<string>('');

  const [isConfirmDeleteModalShown, setIsConfirmDeleteModalShown] = useState<boolean>(false);
  const [isConfirmDeleteUserShown, setIsConfirmDeleteUserShown] = useState<boolean>(false);
  const [emailToDelete, setEmailToDelete] = useState<string>('');
  const [organizationToDelete, setOrganizationToDelete] = useState<string>('');
  const [userToDelete, setUserToDelete] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    refreshData();
  }, []);

  const refreshData = async () => {
    const promises = [];
    promises.push(refreshLoginCounts(setLoginCounts));
    promises.push(refreshAllowedDomains(setAllowedDomains));
    promises.push(refreshMemberships(setUsers, setUserMemberships));
    promises.push(refreshInstallations(setInstallations));
    promises.push(refreshInstallationUsers(setInstallationUsers));
    promises.push(refreshOrganizations(setClusterCounts, setOrgs));
    promises.push(refreshAgentCounts(setAgentCounts));
    promises.push(refreshLastLoginTimes(setLastLoginTimes));
    promises.push(refreshLatestReportEvents(setLatestReportEvents));
    await Promise.all(promises);
    setLoading(false);
  };

  const funnelData = useMemo(() => {
    const funnelData = [];
    if (Object.keys(userMemberships).length && users.length && agentCounts && clusterCounts) {
      const nonAdminUsers = users.filter((user) => !user.Email.includes(`@${AdminDomain}`));
      funnelData.push({
        Funnel: strings.admin.numberUsers,
        Count: nonAdminUsers.length,
      });

      const usersWithAtleastOneOrg = nonAdminUsers.filter((user) => userMemberships[user.Email].length);
      funnelData.push({
        Funnel: strings.admin.numberUsers1Org,
        Count: usersWithAtleastOneOrg.length,
      });

      const usersWithAtLeastOneCluster = usersWithAtleastOneOrg.filter(
        (user) => userMemberships[user.Email].filter((membership) => clusterCounts?.[membership.Organization]).length,
      );
      funnelData.push({
        Funnel: strings.admin.numberUsersOrgCluster,
        Count: usersWithAtLeastOneCluster.length,
      });

      const usersWithData = usersWithAtLeastOneCluster.filter(
        (user) =>
          userMemberships[user.Email].filter((membership) => agentCounts?.[membership.Organization]?.LatestTimeStamp)
            .length,
      );
      funnelData.push({
        Funnel: strings.admin.numberUsersOrgClusterData,
        Count: usersWithData.length,
      });

      const usersWithDataInPastWeek = usersWithData.filter(
        (user) =>
          userMemberships[user.Email].filter((membership) => {
            const latestReportTimStamp = agentCounts[membership.Organization].LatestTimeStamp;
            return dayjs.utc().diff(latestReportTimStamp, 'days') <= 7;
          }).length,
      );
      funnelData.push({
        Funnel: strings.admin.numberUsersOrgClusterData7D,
        Count: usersWithDataInPastWeek.length,
      });
    }

    return funnelData;
  }, [users, userMemberships, agentCounts, clusterCounts]);

  const userTableRows = useMemo(() => {
    const userTableRows: UserDataProp[] = [];
    let userRowIndex = 1;
    if (users.length && Object.keys(userMemberships).length) {
      users.forEach((user) => {
        if (userMemberships[user.Email].length) {
          userMemberships[user.Email].forEach((membership) => {
            let userOrgMap: UserDataProp = getUserType(user);

            if (loginCounts) {
              userOrgMap = getLoginInfoForUser(userOrgMap, user.Email, loginCounts, lastLoginTimes);
            }

            userOrgMap = {
              ...userOrgMap,
              rowIndex: userRowIndex,
              Organization: membership.Organization,
              ClusterCount: clusterCounts?.[membership.Organization],
              OrgMembership: membership?.Role,
            };

            userRowIndex += 1;

            const org = getOrgByName(userOrgMap.Organization, orgs);

            if (org) {
              userOrgMap = getOrgFieldsForUserTableRows(userOrgMap, org, false);
            }
            userOrgMap = getAgentFieldsForUserTableRows(userOrgMap, false, agentCounts);

            userTableRows.push(userOrgMap);
          });
        } else {
          let userOrgMap: UserDataProp = getUserType(user);

          if (loginCounts) {
            userOrgMap = getLoginInfoForUser(userOrgMap, user.Email, loginCounts, lastLoginTimes);
          }

          userOrgMap = {
            ...userOrgMap,
            rowIndex: userRowIndex,
            Organization: strings.general.na,
            ClusterCount: strings.general.na,
            OrgMembership: strings.general.na,
            Actions: strings.general.na,
          };

          userRowIndex += 1;

          userOrgMap = getOrgFieldsForUserTableRows(userOrgMap, null, true);
          userOrgMap = getAgentFieldsForUserTableRows(userOrgMap, true, agentCounts);

          userTableRows.push(userOrgMap);
        }
      });
    }

    return userTableRows;
  }, [users, userMemberships, loginCounts, lastLoginTimes, orgs, agentCounts, clusterCounts]);

  const installationTableRows = useMemo(() => {
    return installations?.map((installation) => {
      const returnInstall: ISelfHostedInstallation = { ...installation };

      returnInstall.OrgTierText = orgTierRewrite?.[installation.Tier];
      returnInstall.UpdateTrial = trialRewrite(returnInstall.TrialExpiresAt || null);
      return returnInstall;
    });
  }, [installations]);

  const updateOrganization = async (org: OrgProp) => {
    try {
      await sendRequest('PATCH', `/v0/admin/organizations/${org.Name}`, { data: org }, null);

      toast.success('Org updated!');
      await refreshOrganizations(setClusterCounts, setOrgs);
    } catch (e) {
      toast.error('Error updating org');
      logger.logError('error_updating_org', e);
    }
  };

  const updateTier = (orgName: string, tier: number) => {
    const org = getOrgByName(orgName, orgs);
    if (org) {
      org.Tier = tier;
      org.TrialExpiresAt = null;
      updateOrganization(org);
    }
  };

  const updateTrial = (orgName: string, duration?: number) => {
    const org: OrgProp | undefined = getOrgByName(orgName, orgs);
    if (org) {
      if (duration) {
        const expiration = new Date();
        expiration.setDate(expiration.getDate() + duration);
        org.Tier = ENTERPRISE_TIER_INT;
        org.TrialExpiresAt = expiration.toISOString();
      } else {
        org.Tier = FREE_TIER_INT;
        // TODO: move duration setting to the backend/API
        org.TrialExpiresAt = new Date().toISOString();
      }
      updateOrganization(org);
    }
  };

  const getUserByEmail = (email: string) => {
    return users.find((user) => user.Email === email);
  };

  const toggleCanCreateOrg = async (email: string) => {
    const user: UserProp | undefined = getUserByEmail(email);
    if (!user) {
      toast.error(`Error updating user's ability to create orgs: User not found`);
      return;
    }

    try {
      const payload = { Email: email, CanCreateOrg: !user.CanCreateOrg };
      await sendRequest('PATCH', '/v0/admin/user', { data: payload }, null);

      toast.success(`User's ability to create orgs updated`);
      await refreshMemberships(setUsers, setUserMemberships);
    } catch (e) {
      toast.error(`Error updating user's ability to create orgs: ${e.message}`);
      logger.logError('error_setting_user_create_org', e);
    }
  };

  const setUserType = async (email: string, selectedUserType: string) => {
    const user: UserProp | undefined = getUserByEmail(email);

    if (!user) {
      toast.error(`Error updating user's type: User not found`);
      return;
    }

    let isSuperAdmin = false;
    let isSuperDuperAdmin = false;

    if (selectedUserType === 'SuperDuperAdmin') {
      isSuperAdmin = true;
      isSuperDuperAdmin = true;
    } else if (selectedUserType === 'SuperAdmin') {
      isSuperAdmin = true;
      isSuperDuperAdmin = false;
    }

    try {
      const payload = { Email: email, IsSuperAdmin: isSuperAdmin, IsSuperDuperAdmin: isSuperDuperAdmin };
      await sendRequest('PATCH', '/v0/admin/user', { data: payload }, null);
      toast.success(`User type updated to ${selectedUserType}`);
      await refreshMemberships(setUsers, setUserMemberships);
    } catch (e) {
      toast.error(`Error updating user's type: ${e.message}`);
      logger.logError('error_setting_user_type', e);
    }
  };

  const onRemoveUserFromOrganizationConfirmed = async () => {
    if (!emailToDelete || !organizationToDelete) {
      return;
    }

    const membership = userMemberships[emailToDelete].find(({ Organization }) => Organization === organizationToDelete);

    try {
      await sendRequest(
        'DELETE',
        `/v0/admin/organizations/${organizationToDelete}/membership`,
        {
          data: membership,
        },
        null,
      );

      toast.success('User was removed from org');
      await refreshMemberships(setUsers, setUserMemberships);
    } catch (e) {
      toast.error('Error while removing user from org');
      logger.logError('error_deleting_user_from_org', e);
    }

    setIsConfirmDeleteModalShown(false);
    setEmailToDelete('');
    setOrganizationToDelete('');
  };

  const removeUserFromOrganization = async (email: string, organization: string) => {
    setUserToDelete('');
    setIsConfirmDeleteUserShown(false);

    setEmailToDelete(email);
    setOrganizationToDelete(organization);
    setIsConfirmDeleteModalShown(true);
  };

  const onDeleteUserConfirmed = async () => {
    if (!userToDelete) {
      return;
    }

    try {
      await sendRequest('DELETE', `/v0/admin/users/${userToDelete}`, {}, null);

      toast.success('User was deleted');
      await refreshMemberships(setUsers, setUserMemberships);
    } catch (e) {
      toast.error('Error while deleting user');
      logger.logError('error_deleting_user', e);
    }

    setIsConfirmDeleteUserShown(false);
    setUserToDelete('');
  };

  const deleteUser = async (email: string) => {
    setEmailToDelete('');
    setOrganizationToDelete('');
    setIsConfirmDeleteModalShown(false);

    setUserToDelete(email);
    setIsConfirmDeleteUserShown(true);
  };

  const formattedLatestReportEvents = () => {
    return (latestReportEvents || []).map((reportEvent) => {
      const formattedEvent = reportEvent;
      formattedEvent.UpdateTime = reportEvent.UpdateTime ? formatTime(reportEvent.UpdateTime) : strings.general.na;
      return formattedEvent;
    });
  };

  const nonAdminUserTableRows = () => {
    return userTableRows.filter((user) => !user.Email.includes(`@${AdminDomain}`));
  };

  const usersToDownload = () => {
    return users
      .filter((user) => !user.Email.includes(`@${AdminDomain}`))
      .map((u) => ({ ...u, LifecycleStage: 'Subscriber', ContactOwner: '' }));
  };

  return (
    <LayoutReact className="admin-panel">
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Form.Group>
            <Form.Control
              id="user-search"
              onChange={(e) => setUserSearch(e.target.value)}
              placeholder={strings.admin.searchForUsers}
              type="search"
              aria-label={strings.admin.searchForUsers}
            />
          </Form.Group>
          <div className="admin-panel__export-buttons">
            <ExportButton
              data={userTableRows}
              filename="all_users"
              text={strings.admin.exportUsersCSV}
              labels={userOrgMapCsvLabels}
            />
            <ExportButton
              data={nonAdminUserTableRows()}
              filename="non_admin_users"
              text={strings.admin.exportNonAdminCSV}
              labels={userOrgMapCsvLabels}
            />
          </div>
          {orgs.length > 0 && (
            <>
              <Users
                usersData={userTableRows}
                search={userSearch}
                updateTier={updateTier}
                updateTrial={updateTrial}
                setUserType={setUserType}
                toggleCanCreateOrg={toggleCanCreateOrg}
                removeUserFromOrganization={removeUserFromOrganization}
                deleteUser={deleteUser}
                router={router}
              />
              <SelfHostedInstallations
                installationsData={installationTableRows}
                search={userSearch}
                setInstallations={setInstallations}
              />
              <SelfHostedUsers
                usersData={installationUsers}
                setInstallationUsers={setInstallationUsers}
                search={userSearch}
              />
              <AllowedDomains domainsData={allowedDomains} setAllowedDomains={setAllowedDomains} />
              <FunnelData funnelData={funnelData} />
            </>
          )}
          <h2
            className={Title({
              size: strings.textStyling.xs,
              weight: strings.textStyling.medium,
              topMargin: strings.textStyling.lg,
            })}
          >
            {strings.admin.Metrics}
          </h2>
          <div className="admin-panel__metrics-export">
            <span>{strings.admin.exportReportEvents}</span>
            <ExportButton
              data={formattedLatestReportEvents()}
              filename="latest_report_events"
              text={strings.admin.downloadReportEvents}
            />
          </div>
          <div className="admin-panel__metrics-export">
            <span>{strings.admin.exportCurrentUsers}</span>
            <ExportButton data={usersToDownload()} filename="current_users" text={strings.admin.downloadCurrentUsers} />
          </div>
          <h2
            className={Title({
              size: strings.textStyling.xs,
              weight: strings.textStyling.medium,
              topMargin: strings.textStyling.lg,
            })}
          >
            {strings.admin.selfHostedCodes}
          </h2>
          <div>
            <p>{strings.admin.selfHostedCodesDesc}</p>
            <pre className="pre-code">
              <code>{installationCode}</code>
            </pre>
            <Button
              variant="primary"
              onClick={() => generateInstallationCode(setInstallationCode)}
              title={strings.admin.generateNewCode}
            >
              {strings.admin.generateNewCode}
            </Button>
          </div>
        </>
      )}
      <ConfirmationDialog
        cancelButtonClasses="custom-cancel-button"
        cancelButtonText={strings.general.Cancel}
        confirmButtonText={strings.general.confirm}
        isModalShown={isConfirmDeleteModalShown || isConfirmDeleteUserShown}
        modalBodyClasses="custom-confirm-modal-body"
        modalContent={
          isConfirmDeleteModalShown
            ? `${strings.admin.removeUser.replace('#email', emailToDelete)} ${
                strings.admin.fromOrg
              } ${organizationToDelete}?`
            : `${strings.admin.deleteUserConfirm.replace('#email', userToDelete)}`
        }
        modalContentClasses="custom-confirm-modal-content"
        modalTitle={isConfirmDeleteModalShown ? strings.admin.removeUserFromOrg : strings.admin.deleteUser}
        onConfirmClicked={isConfirmDeleteModalShown ? onRemoveUserFromOrganizationConfirmed : onDeleteUserConfirmed}
        onModalHidden={(isModalShown: boolean | undefined) => {
          setIsConfirmDeleteModalShown(!!isModalShown);
          setIsConfirmDeleteUserShown(!!isModalShown);

          setEmailToDelete('');
          setOrganizationToDelete('');
          setUserToDelete('');
        }}
      />
      <Toaster />
    </LayoutReact>
  );
};

export default Admin;
