export const ENTERPRISE_TIER_INT = 1;

export const FREE_TIER_INT = 0;

export const orgTierRewrite = ['Free Tier', 'Enterprise Tier', 'Business Tier', 'Disabled Tier'];

export const userOrgMapCsvLabels = {
  Email: 'Email',
  Organization: 'Org',
  ClusterCount: 'Cluster Count',
  AgentCount: 'Agents Installed',
  LatestReportTimestamp: 'Last Report',
  OrgTierText: 'Org Tier',
  TrialExpiresAt: 'Trial Status',
  UpdateTrial: 'Update Trial',
  OrgCreatedOn: 'Org Create Date',
  OrgMembership: 'Org Membership',
  CreatedOn: 'User Create Date',
  LastLoginOn: 'User Last Login',
  AllTimeLoginCount: 'User Login Count',
  WeeklyLoginCount: 'User Login Count(7d)',
  MonthlyLoginCount: 'User Login Count(30d)',
  UserTypeText: 'Update User Type',
  OrgCreatedBy: 'Org Created by',
};
