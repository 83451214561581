import React, { useState, useMemo, useEffect } from 'react';
import Fuse from 'fuse.js';
import { Button, Form } from 'react-bootstrap';

import TableWithPagination from '../tableWithPagination/tableWithPagination.react';

import { addAllowedDomain } from '../../Admin.helpers.react';

import { DOMAINS_TABLE_COLUMNS } from '../components.config.react';

import { AllowedDomainsDataProp } from '~views/admin/Admin.types.react';

import { strings } from '~utils/strings';
import { Title, CardTitleDefault } from '~utils/texts.react';

import './allowedDomains.react.scss';

type AllowedDomainsProp = {
  domainsData: AllowedDomainsDataProp[];
  setAllowedDomains: React.Dispatch<React.SetStateAction<AllowedDomainsDataProp[]>>;
};

const AllowedDomains = ({ domainsData, setAllowedDomains }: AllowedDomainsProp) => {
  const [search, setSearch] = useState<string>('');
  const [newDomain, setNewDomain] = useState<string>('');
  const [filteredDomains, setFilteredDomains] = useState<AllowedDomainsDataProp[]>([]);

  useEffect(() => {
    setFilteredDomains(domainsData);
  }, [domainsData.length]);

  const searchInstance = useMemo(() => {
    return new Fuse(domainsData as AllowedDomainsDataProp[], {
      findAllMatches: true,
      threshold: 0.2,
      ignoreLocation: true,
      keys: [strings.noTranslate.domain],
    });
  }, [domainsData.length]);

  useEffect(() => {
    if (!search.length) {
      setFilteredDomains(domainsData);
    } else {
      const results = searchInstance.search(search).map((res) => res.item);
      setFilteredDomains(results);
    }
  }, [search, domainsData]);

  const columns = useMemo(() => DOMAINS_TABLE_COLUMNS(setAllowedDomains), [filteredDomains]);

  const data = useMemo(() => filteredDomains, [filteredDomains, domainsData]);

  return (
    <div className="allowed-domains">
      <h2
        className={Title({
          size: strings.textStyling.xs,
          weight: strings.textStyling.medium,
          topMargin: strings.textStyling.lg,
        })}
      >
        {strings.admin.allowedDomains}
      </h2>
      <div className="allowed-domains__top-container">
        <Form.Group>
          <Form.Control
            id="allowed-domains-search"
            onChange={(e) => setSearch(e.target.value)}
            placeholder={strings.admin.allowedDomainsSearch}
            type="search"
            aria-label={strings.admin.allowedDomainsSearchLabel}
          />
        </Form.Group>
      </div>
      <TableWithPagination
        columns={columns}
        data={data}
        filteredLength={filteredDomains.length}
        tableCaption={strings.admin.allowedDomainCaption}
      />
      <div>
        <h3 className={CardTitleDefault()}>{strings.admin.addAllowedDomains}</h3>
        <div className="allowed-domains__add-domain">
          <Form.Control
            id="add-allowed-domain"
            onChange={(e) => setNewDomain(e.target.value)}
            placeholder="example.com"
            type="text"
            aria-label={strings.admin.addAllowedDomainLabel}
          />
          <Button
            onClick={() => addAllowedDomain(newDomain, setAllowedDomains)}
            disabled={!newDomain.length}
          >
            {strings.admin.addAllowedDomains}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AllowedDomains;
