import Admin from './Admin.react';

export default {
  methods: {
    router() {
      return this.$router;
    },
  },
  components: {
    Admin,
  },
};
